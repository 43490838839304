import { configureStore } from '@reduxjs/toolkit'
import authData from 'model/reducers/authData'
import tours from 'model/reducers/tours'
import slots from 'model/reducers/slots'
import items from 'model/reducers/items'
import user from 'model/reducers/user'
import tourModels from 'model/reducers/tourModels'
import mattertags from 'model/reducers/mattertags'
import suppliers from 'model/reducers/suppliers'
import settings from 'model/reducers/settings'
import requestsDesign from 'model/reducers/requestsDesign'
import requestsInProgress from 'model/reducers/requestsInProgress'
import requestsCompleted from 'model/reducers/requestsCompleted'
import roomTypes from 'model/reducers/roomTypes'
import templates from 'model/reducers/templates'
import partners from 'model/reducers/partners'

const store = configureStore({
  reducer: {
    authData,
    tours,
    slots,
    items,
    user,
    tourModels,
    mattertags,
    suppliers,
    settings,
    requestsDesign,
    requestsInProgress,
    requestsCompleted,
    roomTypes,
    templates,
    partners
  }
})
export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
