import { onSnapshot, doc, updateDoc, setDoc } from 'firebase/firestore'
import { User } from 'firebase/auth'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveUser } from 'model/actions'
import { DesignerT } from 'shared/types/model'

const dbCreateDesigner = async (authData: User) => {
  try {
    const dbUser: Partial<DesignerT> = {
      id: authData.uid,
      createdAt: _.now()
    }
    if (!_.isNil(authData.displayName)) {
      dbUser.name = authData.displayName
    }
    if (!_.isNil(authData.email)) {
      dbUser.email = authData.email
    }
    if (authData.photoURL) {
      dbUser.avatar = authData.photoURL
    }
    const ref = doc(db, `designers/${authData.uid}`)
    await setDoc(ref, dbUser, { merge: true })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchUser = (authData: User) => {
  const userId = authData.uid
  try {
    const unsubscribe = onSnapshot(
      doc(db, 'designers', userId),
      { includeMetadataChanges: true },
      userSN => {
        console.log('onUserChaged')
        const user = userSN.data() as DesignerT | null
        console.log('onUserChaged')
        if (user) {
          store.dispatch(receiveUser(user))
          Sentry.setUser({
            id: userId,
            email: _.get(user, 'email', ''),
            username: _.get(user, 'name', '')
          })
        } else {
          console.log('the user does not exist: create it')
          dbCreateDesigner(authData)
          // signOut(auth)
        }
      },
      err => {
        console.log('fetch user error', err)
      }
    )
    addListener('user', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchUser error', e)
  }
}

export const dbUpdateUser = async (
  userId: string,
  update: Partial<DesignerT>
) => {
  try {
    const ref = doc(db, 'designers', userId)
    await updateDoc(ref, update)
    return true
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateSettings error', e)
    return false
  }
}
