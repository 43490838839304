import {
  onSnapshot,
  collection,
  query,
  setDoc,
  doc,
  updateDoc,
  deleteField,
  where
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveTours } from 'model/actions'
import { TourSlotT, TourT } from 'shared/types/model'

export const fetchTours = (userId: string) => {
  try {
    console.log('fetch tours', userId)
    const q = query(collection(db, 'tours'), where('designerId', '==', userId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        console.log('tours received, amount', _.size(res))
        store.dispatch(receiveTours(res))
      },
      err => {
        console.log(`fetchProjects error: ${err}`)
      }
    )
    addListener('projects', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchProjects error', e)
  }
}

export const dbCreateTour = async (tour: TourT) => {
  try {
    console.log('createTour', tour)
    const ref = doc(db, `tours/${tour.id}`)
    setDoc(ref, _.omitBy(tour, _.isNil))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTourSlot = async (
  tourId: string,
  tourSlotId: string,
  params: Partial<TourSlotT>
) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    const update = {
      [`slots.${tourSlotId}`]: params
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteTourSlot = async (tourId: string, tourSlotId: string) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    const update = {
      [`slots.${tourSlotId}`]: deleteField()
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteAllItems = async (tourId: string) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    const update = {
      slots: deleteField()
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTourPath = async (
  tourId: string,
  path: string,
  value: any
) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    const update = {
      [path]: value
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateTour = (tourId: string, update: any) => {
  try {
    const ref = doc(db, `tours/${tourId}`)
    updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteTour = (tourId: string) => {
  try {
    updateTour(tourId, { deleted: _.now() })
  } catch (e) {
    Sentry.captureException(e)
  }
}
