import { Provider } from 'react-redux'
import AppRouter from 'navigation/AppRouter'
import store from 'model/store'
import { ChakraProvider, theme } from '@chakra-ui/react'
import 'react-base-table/styles.css'
import config from 'shared/config'

console.log(
  `%cUpStager application version ${config.internalVersion}`,
  'color: gold; font-weight: bold'
)

const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AppRouter />
      </ChakraProvider>
    </Provider>
  )
}

export default App
