import { FC } from 'react'
import { Flex, Image, Text } from '@chakra-ui/react'
import NoResponsesImage from 'shared/assets/no_notification.png'

type Props = {}

const RequestsEmptyState: FC<Props> = () => (
  <Flex
    justifyContent={{ base: 'flex-start', lg: 'center' }}
    alignItems='center'
    w='full'
    h='full'
    direction='column'
    gap='6'
    p={{ base: '16', lg: 0 }}
    position='relative'
  >
    <Image src={NoResponsesImage} alt='No tours' boxSize={44} />
    <Text
      maxWidth='96'
      textAlign='center'
      fontWeight='normal'
      fontSize='md'
      lineHeight='24px'
      color='gray.600'
    >
      No tours available yet
    </Text>
  </Flex>
)

export default RequestsEmptyState
