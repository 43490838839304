import { FC } from 'react'
import { Flex, Image, Text, Button, HStack } from '@chakra-ui/react'
import _ from 'lodash'

import NoResponsesImage from 'shared/assets/no_notification.png'
import { DesignerT } from 'types/model'

type Props = {
  onRequestAccess: () => void
  user: DesignerT
}

const UnapprovedState: FC<Props> = ({ onRequestAccess, user }) => {
  const getMessage = () => {
    if (_.isNil(user.requestAccess)) {
      return 'You have not access to handle requests at the moment. If you are a designer, press the button below to request access.'
    } else if (_.isNil(user.isApproved)) {
      return 'Your request is under review.'
    } else if (!_.isNil(user.isApproved) && !user.isApproved) {
      return 'Access denied'
    }
  }

  const showButton = _.isNil(user.requestAccess) && _.isNil(user.isApproved)

  return (
    <Flex
      justifyContent={{ base: 'flex-start', lg: 'center' }}
      alignItems='center'
      w='full'
      h='full'
      direction='column'
      gap='6'
      p={{ base: '16', lg: 0 }}
      position='relative'
    >
      <Image src={NoResponsesImage} alt='No tours' boxSize={44} />
      <Text
        maxWidth='96'
        textAlign='center'
        fontWeight='normal'
        fontSize='md'
        lineHeight='24px'
        color='gray.600'
      >
        {getMessage()}
      </Text>

      <HStack spacing={4}>
        {showButton && (
          <Button
            w='56'
            h='12'
            fontWeight='semibold'
            variant='solid'
            colorScheme='teal'
            fontSize='lg'
            lineHeight='7'
            onClick={onRequestAccess}
          >
            Request access
          </Button>
        )}
      </HStack>
    </Flex>
  )
}

export default UnapprovedState
