import { FC } from 'react'
import { HStack, VStack, Text, Heading } from '@chakra-ui/react'

type Props = {}

const RequestsNavBar: FC<Props> = () => {
  return (
    <HStack
      px='4'
      w='full'
      align='center'
      justify='space-between'
      h='28'
      flexShrink={0}
      bg='white'
      borderLeftWidth={1}
      borderLeftColor='gray.50'
      borderBottomWidth={1}
      borderBottomColor='gray.100'
    >
      <VStack align='flex-start'>
        <Heading>Requests</Heading>
        <Text>
          The tours availbale for interior design creation. Choose tours you
          would like to work on.
        </Text>
      </VStack>
    </HStack>
  )
}

export default RequestsNavBar
