import {
  DesignerT,
  DictT,
  RoomTypeT,
  ItemT,
  PriceT,
  ProductT,
  RequestDesignT,
  SettingsT,
  SlotT,
  SubscriptionT,
  SupplierT,
  TourModelT,
  TourT,
  ItemsTemplateT,
  PartnerT
} from 'shared/types/model'
import { createAction } from '@reduxjs/toolkit'

export const receiveRoomTypes =
  createAction<DictT<RoomTypeT>>('RECEIVE_ROOM_TYPES')

export const receiveTemplates =
  createAction<DictT<ItemsTemplateT>>('RECEIVE_TEMPLATES')

export const clear = createAction('CLEAR')
export const logout = createAction('LOGOUT')
export const receiveAuthData = createAction<{
  uid: string
  email: string | null
} | null>('RECEIVE_AUTH_DATA')

export const receiveItems = createAction<DictT<ItemT>>('RECEIVE_ITEMS')
export const clearItems = createAction('CLEAR_ITEMS')

export const receiveRequestsDesign = createAction<DictT<RequestDesignT>>(
  'RECEIVE_REQUESTS_DESIGN'
)
export const receiveRequestsCompleted = createAction<RequestDesignT[]>(
  'RECEIVE_REQUESTS_COMPLETED'
)
export const receiveRequestsInProgress = createAction<DictT<RequestDesignT>>(
  'RECEIVE_REQUESTS_IN_PROGRESS'
)

export const receiveSettings = createAction<SettingsT>('RECEIVE_SETTINGS')
export const receiveSlots = createAction<DictT<SlotT>>('RECEIVE_SLOTS')
export const receiveSuppliers =
  createAction<DictT<SupplierT>>('RECEIVE_SUPPLIERS')
export const receiveTourModels = createAction<DictT<TourModelT> | null>(
  'RECEIVE_TOUR_MODELS'
)

export const addMattertag = createAction<{ tourSlotId: string; tagId: string }>(
  'ADD_MATTERTAG'
)
export const removeMattertag = createAction<{ tourSlotId: string }>(
  'REMOVE_MATTERTAG'
)
export const resetMattertags = createAction('RESET_MATTERTAGS')

export const receivePrices = createAction<DictT<PriceT>>('RECEIVE_PRICES')
export const receiveProducts = createAction<DictT<ProductT>>('RECEIVE_PRODUCTS')
export const receiveSubscription = createAction<SubscriptionT>(
  'RECEIVE_SUBSCRIPTION'
)

export const receiveTours = createAction<DictT<TourT>>('RECEIVE_TOURS')
export const receiveUser = createAction<DesignerT>('RECEIVE_USER')
export const receivePartners = createAction<DictT<PartnerT>>('RECEIVE_PARTNERS')
