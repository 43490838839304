import { FC, ReactNode, useEffect } from 'react'
import { Box, useMediaQuery } from '@chakra-ui/react'

import { appInitialized } from 'controllers/init'
import { useNavigate } from 'react-router-dom'
import MobilePlaceholder from 'shared/components/MobilePlaceholder'

const NavWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const [isDesktop] = useMediaQuery('(min-width: 1280px)')
  useEffect(() => {
    console.log('call appInitialized')
    if (isDesktop) {
      appInitialized(navigate)
    }
  }, [isDesktop])

  if (isDesktop) {
    return (
      <Box w='full' h='100vh'>
        {children}
      </Box>
    )
  } else {
    return <MobilePlaceholder />
  }
}

export default NavWrapper
