import {
  onSnapshot,
  query,
  collection,
  where,
  doc,
  writeBatch,
  updateDoc,
  orderBy
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { auth, db, generateId } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { RequestDesignT, TourModelT, TourT } from 'shared/types/model'
import {
  receiveRequestsDesign,
  receiveRequestsInProgress,
  receiveRequestsCompleted
} from 'model/actions'

export const fetchRequestsDesign = () => {
  try {
    const q = query(
      collection(db, 'requestsDesign'),
      where('assignedTo', '==', null),
      where('stripeInvoice.paid', '==', true)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data() as RequestDesignT
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveRequestsDesign(res))
      },
      err => {
        console.log(`fetchRequestsDesign error: ${err}`)
      }
    )
    addListener('requestsDesign', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchRequestsDesign error', e)
  }
}

export const fetchRequestsInProgress = (userId: string) => {
  try {
    console.log('fetchRequestsInProgress', userId)
    const q = query(
      collection(db, 'requestsDesign'),
      where('assignedTo', '==', userId),
      where('completed', '==', false)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data() as RequestDesignT
          _.set(res, doc.id, p)
        })
        console.log('receiveRequestsInProgress', res)
        store.dispatch(receiveRequestsInProgress(res))
      },
      err => {
        console.log(`fetchRequestsInProgress error: ${err}`)
      }
    )
    addListener('requestsInProgress', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchRequestsInProgress error', e)
  }
}

export const fetchRequestsCompleted = (userId: string) => {
  try {
    const q = query(
      collection(db, 'requestsDesign'),
      where('assignedTo', '==', userId),
      where('completed', '==', true),
      orderBy('completedAt')
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = _.map(sn.docs, doc => doc.data() as RequestDesignT)
        console.log('receive requests completed', res)
        store.dispatch(receiveRequestsCompleted(res))
      },
      err => {
        console.log(`fetchRequestsCompleted error: ${err}`)
      }
    )
    addListener('requestsCompleted', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchRequestsCompleted error', e)
  }
}

export const dbAcceptRequest = async (rd: RequestDesignT, tm: TourModelT) => {
  try {
    const currentUser = auth.currentUser
    if (currentUser) {
      const batch = writeBatch(db)
      const rdUpd: Partial<RequestDesignT> = {
        assignedTo: currentUser.uid,
        assignedAt: _.now()
      }
      if (rd.tourId) {
        const tourUpd = {
          designerId: currentUser.uid
        }
        const ref = doc(db, `tours/${rd.tourId}`)
        batch.update(ref, tourUpd)
      } else {
        const id = generateId()
        const tour: TourT = {
          id,
          name: 'Professional design',
          accountId: rd.accountId,
          tourModelId: rd.tourModelId,
          createdAt: _.now(),
          slots: {},
          enabled: false,
          deleted: 0,
          tourModelDeleted: 0,
          designerId: currentUser.uid,
          partnerId: tm.partnerId
        }
        const ref = doc(db, `tours/${id}`)
        batch.set(ref, tour)
        rdUpd.tourId = id
      }
      const ref = doc(db, `requestsDesign/${rd.id}`)
      batch.update(ref, rdUpd)
      await batch.commit()
      return true
    }
  } catch (e) {
    Sentry.captureException(e)
    console.error('accept design request error:', e)
    return false
  }
}

export const dbMarkDesignCompleted = async (rd: RequestDesignT) => {
  try {
    const batch = writeBatch(db)
    const dUpd = {
      completed: true,
      completedAt: _.now()
    }
    batch.update(doc(db, `requestsDesign/${rd.id}`), dUpd)
    const tUpd = {
      userId: rd.userId,
      enabled: true
    }
    batch.update(doc(db, `tours/${rd.tourId}`), tUpd)
    await batch.commit()
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateRequestDesign = async (
  rdId: string,
  upd: Partial<RequestDesignT>
) => {
  try {
    const ref = doc(db, `requestsDesign/${rdId}`)
    await updateDoc(ref, upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}
