import { FC, useState, useMemo } from 'react'
import {
  Flex,
  WrapItem,
  Image,
  Text,
  Button,
  HStack,
  VStack,
  Tooltip,
  IconButton,
  Box
} from '@chakra-ui/react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-regular-svg-icons'

import { RequestDesignT, TourModelT } from 'shared/types/model'
import { noImageUrl } from 'shared/constants/helpers'
import moment from 'moment'
import { dbAcceptRequest } from 'controllers/requests'
import { useNavigate } from 'react-router'
import { useSelector } from 'model/hooks'

type Props = {
  tm: TourModelT
  rd: RequestDesignT
}

const RequestCard: FC<Props> = ({ tm, rd }) => {
  const [isLoading, setIsLoading] = useState(false)
  const partners = useSelector(state => state.partners)
  const navigate = useNavigate()

  const partner = useMemo(() => {
    if (tm.partnerId) {
      return _.get(partners, tm.partnerId, null)
    } else {
      return null
    }
  }, [partners, tm])

  const onAccept = async () => {
    setIsLoading(true)
    const accepted = await dbAcceptRequest(rd, tm)
    setIsLoading(false)
    if (accepted) {
      navigate(`/design/${rd.id}`)
    }
  }

  const renderActionButton = () => {
    return (
      <Button
        variant={'solid'}
        colorScheme='blue'
        onClick={onAccept}
        size='lg'
        isLoading={isLoading}
      >
        Accept
      </Button>
    )
  }

  return (
    <WrapItem key={rd.id} w='sm' h='xs' position='relative' rounded={'xl'}>
      <Image
        src={tm.photoUrl || noImageUrl}
        rounded={'xl'}
        w='sm'
        h='xs'
        objectFit={'cover'}
      />
      <Flex
        w='full'
        h='full'
        position='absolute'
        left={0}
        top={0}
        rounded={'xl'}
        direction='column'
        justify={'space-between'}
      >
        <Flex
          flex={1}
          rounded='xl'
          bgGradient={'linear(to-t, #00000000, #ffffffaa)'}
          mb={8}
          px={2}
          justify='flex-end'
          align='flex-start'
          position={'relative'}
        ></Flex>
        {partner && partner.logoUrl && (
          <Box position='absolute' right={6} top={4}>
            <Image src={partner.logoUrl} w={'20'} />
          </Box>
        )}
        <HStack justify={'center'}>{renderActionButton()}</HStack>
        <HStack
          flex={1}
          rounded='xl'
          bgGradient={'linear(to-b, #00000000, #000000aa)'}
          justify='space-between'
          align='flex-end'
          px={4}
          py={6}
        >
          <HStack justify='space-between' align='flex-end' w='full'>
            <VStack spacing={0} align='flex-start'>
              <Text
                fontSize='2xl'
                px={2}
                w='100%'
                color='white'
                lineHeight={1.4}
              >
                {_.get(tm, 'name', 'Request design')}
              </Text>
              <Text
                px={2}
                color='white'
                fontWeight={'normal'}
                fontSize='sm'
                textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
              >
                {moment(rd.createdAt).format('lll')}
              </Text>
            </VStack>
            <Tooltip label='Open the Matterport model in a new tab' hasArrow>
              <IconButton
                color='white'
                aria-label='view tour'
                icon={<FontAwesomeIcon icon={faEye} />}
                variant='link'
                onClick={() =>
                  window.open(
                    `https://my.matterport.com/show/?m=${tm.modelId}&play=1&ts=5&qs=1&brand=0&dh=1&gt=1&hr=1&mls=2&mt=0&pin=0&portal=0&f=1&lang=en&nozoom=0&wh=1&kb=1&lp=0&title=0&tourcta=0&vr=1`,
                    '_blank'
                  )
                }
              />
            </Tooltip>
          </HStack>
        </HStack>
      </Flex>
    </WrapItem>
  )
}

export default RequestCard
