import { FC } from 'react'
import {
  Avatar,
  HStack,
  VStack,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { faPowerOff, faGear } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { useNavigate } from 'react-router-dom'
import { dbSignOut } from 'controllers/auth'
import { auth } from 'controllers/db'

type Props = {}

const AccountButton: FC<Props> = () => {
  const user = useSelector(state => state.user)
  const navigate = useNavigate()

  const menuItems = (
    <MenuList color={'gray.500'}>
      <MenuItem
        icon={<FontAwesomeIcon icon={faGear} fixedWidth />}
        fontWeight='semibold'
        onClick={() => navigate('/settings')}
      >
        Settings
      </MenuItem>
      <MenuItem
        icon={<FontAwesomeIcon icon={faPowerOff} fixedWidth />}
        fontWeight='semibold'
        onClick={() => dbSignOut(navigate)}
      >
        Log out
      </MenuItem>
    </MenuList>
  )

  console.log('user avatar', user?.avatar)

  return (
    <Menu>
      <MenuButton w={'full'} px={4} my={0}>
        <HStack w='full' flexGrow={0} flexShrink={0} maxW='full'>
          <Avatar
            size='sm'
            name={user?.name}
            src={user?.avatar}
            color='white'
            bg='teal.700'
          />
          <VStack align='flex-start' spacing={0} flex={1} overflow='hidden'>
            <Text
              fontSize={'sm'}
              fontWeight='medium'
              color='black'
              noOfLines={1}
              wordBreak='break-all'
            >
              {_.get(user, 'name', auth.currentUser?.email)}
            </Text>
            <Text
              fontSize={'xs'}
              color='gray.500'
              noOfLines={1}
              wordBreak='break-all'
            >
              {_.get(user, 'email')}
            </Text>
          </VStack>
          <Box color='gray.500' fontSize={'0.8em'}>
            <FontAwesomeIcon icon={faChevronDown} />
          </Box>
        </HStack>
      </MenuButton>
      {menuItems}
    </Menu>
  )
}

export default AccountButton
