import { FC, ReactNode } from 'react'
import {
  ButtonProps,
  HStack,
  Text,
  ButtonGroup,
  useBreakpointValue,
  VStack
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import _ from 'lodash'

interface NavButtonProps extends ButtonProps {
  icon?: IconProp
  label?: string
  description?: string
  color?: string
  iconSize?: 'sm' | 'xs' | 'lg'
  iconColor?: string
  onClick: () => void
  fontSize?: string
  fontWeight?: string
  isActive?: boolean
  options?: ReactNode
}

export const NavButton: FC<NavButtonProps> = ({
  icon,
  label,
  description,
  color,
  iconSize = 'sm',
  iconColor,
  onClick,
  fontSize = 'sm',
  fontWeight = 'medium',
  isActive,
  options,
  className
}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <ButtonGroup
      justifyContent='space-between'
      w='100%'
      isAttached
      role={isDesktop ? 'group' : 'none'}
      _hover={{ bg: 'gray.100' }}
      bg={isActive ? 'gray.100' : undefined}
      spacing={12}
      className={className}
    >
      <HStack
        spacing='2'
        justify={'flex-start'}
        as='button'
        onClick={onClick}
        onFocus={isDesktop ? undefined : onClick}
        h='9'
        pl='3'
        w='full'
        color={iconColor}
      >
        {!_.isNil(icon) && <FontAwesomeIcon icon={icon} size={iconSize} />}
        <VStack w='full' spacing={0} align='flex-start'>
          <Text
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            textAlign='left'
            noOfLines={1}
          >
            {label}
          </Text>
          <Text
            fontSize={'xx-small'}
            fontWeight={'normal'}
            color={'gray.600'}
            textAlign='left'
            noOfLines={1}
          >
            {description}
          </Text>
        </VStack>
      </HStack>
      {options}
    </ButtonGroup>
  )
}
