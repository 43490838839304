import {
  ForwardRefRenderFunction,
  Ref,
  useImperativeHandle,
  forwardRef,
  useRef
} from 'react'
import {
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react'

export type IMarkCompletedAlert = {
  open: () => void
}

type Props = {
  onMarkCompleted: () => void
}

const MarkCompletedAlert: ForwardRefRenderFunction<
  IMarkCompletedAlert,
  Props
> = ({ onMarkCompleted }, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  const onDone = () => {
    onMarkCompleted()
    onClose()
  }

  return (
    <AlertDialog
      motionPreset='slideInBottom'
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Mark the design as completed</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          The design will be delivered to the customer. Are you sure the design
          is completed?
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            No
          </Button>
          <Button colorScheme='teal' ml={3} onClick={onDone}>
            Yes
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default forwardRef(MarkCompletedAlert)
