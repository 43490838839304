import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { NavigateFunction } from 'react-router-dom'
import { auth } from 'controllers/db'
import { onAuthStateChanged, User } from 'firebase/auth'
import { receiveAuthData, logout } from 'model/actions'
import { fetchTours } from 'controllers/tours'
import { fetchSlots } from 'controllers/slots'
import { fetchUser } from 'controllers/user'
import store from 'model/store'
import { clearListeners } from 'controllers/listeners'
import { fetchTourModels } from 'controllers/tourModels'
import { fetchSuppliers } from 'controllers/suppliers'
// import { fetchSettings } from 'controllers/settings'
import {
  fetchRequestsCompleted,
  fetchRequestsDesign,
  fetchRequestsInProgress
} from 'controllers/requests'
import { fetchRoomTypes } from 'controllers/roomTypes'
import { fetchTemplates } from 'controllers/templates'
import { fetchPartners } from 'controllers/partners'

const init = async (authData: User) => {
  try {
    const userId = authData.uid
    console.log('INIT', userId)
    // const settings = await fetchSettings()
    // if (settings) {
    await Promise.all([
      fetchUser(authData),
      fetchRequestsDesign(),
      fetchRequestsInProgress(userId),
      fetchRequestsCompleted(userId),
      fetchTourModels(),
      fetchTours(userId),
      fetchSlots(),
      fetchSuppliers(),
      fetchRoomTypes(),
      fetchTemplates(),
      fetchPartners()
    ])
  } catch (e) {
    Sentry.captureException(e)
  }
}

const onAuth = (authData: User | null, navigate: NavigateFunction) => {
  console.log('onAuthStateChanged', authData)
  if (authData) {
    console.log('authData', authData)
    store.dispatch(
      receiveAuthData({ uid: authData.uid, email: authData.email })
    )
    const pathname = window.location.pathname
    if (_.startsWith(pathname, '/auth')) {
      navigate('/')
    }
    init(authData)
  } else {
    store.dispatch(receiveAuthData(null))
    clearListeners()
    navigate('/auth/signin')
    store.dispatch(logout())
  }
}

export const appInitialized = (navigate: NavigateFunction): void => {
  try {
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    Sentry.captureException(e)
    console.log('app initialization error', e)
  }
}
