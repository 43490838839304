import { onSnapshot, query, collection } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveSlots } from 'model/actions'
import { DictT, SlotT } from 'shared/types/model'
import { dbGetLastItemBySlot } from 'controllers/items'

export const fetchSlots = () => {
  try {
    const q = query(collection(db, 'slots'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<SlotT> = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveSlots(res))
      },
      err => {
        console.log(`fetchSlots error: ${err}`)
      }
    )
    addListener('slots', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchSlots error', e)
  }
}

export const dbUpdateSlotsLastItemId = async (partnerId: string | null) => {
  const state = store.getState()
  const slots = state.slots
  const slotsWithLastItems = await Promise.all(
    _.map(slots, async s => {
      const lastItemId = await dbGetLastItemBySlot(s.id, partnerId)
      return { ...s, lastItemId }
    })
  )
  store.dispatch(receiveSlots(_.keyBy(slotsWithLastItems, 'id')))
}
