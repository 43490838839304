import { FC, useMemo, useRef } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  Text
} from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { useSelector } from 'model/hooks'
import { DictT, ItemT, TourSlotT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PanelCartItem from 'shared/components/PanelCartItem'
import {
  faCheck,
  faPlus,
  faGrid2Plus
} from '@fortawesome/pro-regular-svg-icons'
import { MpSdk } from 'shared/bundle/sdk'
import MarkCompletedAlert, {
  IMarkCompletedAlert
} from 'modals/MarkCompletedAlert'
import UnpricedItems from 'shared/components/UnpricedItems'

type Props = {
  sdk: MpSdk
  tourSlots: DictT<TourSlotT>
  onAddFurnitureClick: () => void
  onAddSetClick?: () => void
  openProductCard: (item: ItemT) => void
  addToRoom: (itemId: string) => void
  removeItem: (itemId: string) => void
  selectItem: (itemId: string) => void
  isCompleted?: boolean
  onMarkCompleted?: () => void
}

const ProDesignPanel: FC<Props> = ({
  sdk,
  tourSlots,
  onAddFurnitureClick,
  onAddSetClick,
  openProductCard,
  addToRoom,
  removeItem,
  isCompleted,
  onMarkCompleted,
  selectItem
}) => {
  const items = useSelector(state => state.items)
  const suppliers = useSelector(state => state.suppliers)
  const mattertags = useSelector(state => state.mattertags)
  const markCompletedAlertRef = useRef<IMarkCompletedAlert>(null)
  const sortedItems = useMemo(() => {
    const byItemId: DictT<TourSlotT[]> = _.groupBy(tourSlots, 'itemId')
    const itemsAr = _.map(byItemId, items => _.sortBy(items, 'createdAt'))
    return _.sortBy(itemsAr, ar => ar[0].createdAt)
  }, [tourSlots])

  const unpricedItems = useMemo(() => {
    const res: DictT<ItemT> = {}
    _.forEach(tourSlots, ts => {
      const item = _.get(items, ts.itemId)
      if (_.isNil(item.price)) {
        res[item.id] = item
      }
    })
    return _.values(res)
  }, [tourSlots, items])

  const renderCartItems = () => {
    if (items) {
      const res: any = []
      _.forEach(sortedItems, ar => {
        _.forEach(ar, (tourSlot, i) => {
          const item = _.get(items, tourSlot.itemId)
          if (item) {
            res.push(
              <PanelCartItem
                sdk={sdk}
                key={tourSlot.id}
                item={item}
                index={i}
                quantity={_.size(ar)}
                openProductCard={openProductCard}
                addToRoom={addToRoom}
                onRemove={() => removeItem(tourSlot.id)}
                onSelect={() => selectItem(tourSlot.id)}
                mattertagId={_.get(mattertags, tourSlot.id)}
                supplierName={
                  item.supplierId && _.get(suppliers, [item.supplierId, 'name'])
                }
                canEdit
                pricesVisible
              />
            )
          } else {
            console.warn('item of tour slot not found, ts:', tourSlot)
          }
        })
      })
      return res
    }
  }

  const renderCompleted = () => {
    if (isCompleted) {
      return (
        <VStack>
          <Text>Completed</Text>
        </VStack>
      )
    } else {
      return (
        <Button
          leftIcon={<FontAwesomeIcon icon={faCheck} />}
          onClick={() => markCompletedAlertRef.current?.open()}
          variant={'outline'}
          colorScheme='blue'
          size='sm'
        >
          Mark completed
        </Button>
      )
    }
  }

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='center' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {'Design'}
                </Heading>
              </VStack>
            </HStack>
            {renderCompleted()}
          </Flex>
        </Box>
        <VStack
          direction='column'
          align={'center'}
          justify='flex-start'
          flex={1}
          h='full'
          overflowX='hidden'
          overflowY={'auto'}
          sx={{ '.divider': { margin: 0 } }}
          divider={
            <Box
              className='divider'
              borderColor={'gray.300'}
              borderBottomWidth={1}
              w='full'
            />
          }
          scrollSnapType={'y proximity'}
        >
          {renderCartItems()}
        </VStack>
        <VStack bg='white' w='full' p={4} spacing={4}>
          <UnpricedItems items={unpricedItems} />
          <HStack w='full'>
            <Button
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              w='full'
              // variant={'primary'}
              size='lg'
              colorScheme={'blue'}
              onClick={() => onAddFurnitureClick()}
            >
              Add item
            </Button>
            <Button
              leftIcon={<FontAwesomeIcon icon={faGrid2Plus} />}
              w='full'
              variant={'outline'}
              size='lg'
              colorScheme={'blue'}
              onClick={() => onAddSetClick && onAddSetClick()}
            >
              Add set
            </Button>
          </HStack>
        </VStack>
        <MarkCompletedAlert
          ref={markCompletedAlertRef}
          onMarkCompleted={
            onMarkCompleted ? () => onMarkCompleted() : () => null
          }
        />
      </Flex>
    </RightPanelWrapper>
  )
}

export default ProDesignPanel
