import { collection, query, onSnapshot } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { TourModelT } from 'shared/types/model'
import { receiveTourModels } from 'model/actions'
import { addListener } from 'controllers/listeners'

export const fetchTourModels = async () => {
  try {
    console.log('fetch tour models')
    const q = query(collection(db, 'tourModels'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data() as TourModelT
          _.set(res, doc.id, p)
        })
        console.log('tour models received, amount', _.size(res))
        store.dispatch(receiveTourModels(res))
      },
      err => {
        console.log(`fetchProjects error: ${err}`)
      }
    )
    addListener('tourModels', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch tour models error', e)
  }
}
