import { Flex } from '@chakra-ui/react'
import CompletedContent from 'pages/completed/CompletedContent'
import CompletedNavBar from 'pages/completed/CompletedNavBar'

const Completed = () => {
  return (
    <Flex direction='column' h='full' w='full' bg='gray.50'>
      <CompletedNavBar />
      <CompletedContent />
    </Flex>
  )
}

export default Completed
