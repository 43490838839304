import { createReducer } from '@reduxjs/toolkit'
import { RequestDesignT } from 'types/model'
import { receiveRequestsCompleted, clear, logout } from 'model/actions'

const initialState = [] as RequestDesignT[]

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveRequestsCompleted, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
