import { query, collection, getDocs } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { PartnerT } from 'shared/types/model'
import { receivePartners } from 'model/actions'

export const fetchPartners = async () => {
  try {
    const q = query(collection(db, 'partners'))
    const sn = await getDocs(q)
    const res = {}
    sn.forEach(doc => {
      const p = doc.data() as PartnerT
      _.set(res, doc.id, p)
    })
    store.dispatch(receivePartners(res))
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchPartners error', e)
  }
}
