import { FC, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { Flex } from '@chakra-ui/react'
import { faHouse } from '@fortawesome/pro-regular-svg-icons'
import { NavButton } from 'components/sidebar/NavButton'
import { RequestDesignT } from 'shared/types/model'
import { useSelector } from 'model/hooks'

type Props = {
  rd: RequestDesignT
}

export const SideMenuNavButton: FC<Props> = ({ rd }) => {
  const tourModels = useSelector(state => state.tourModels)
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = useMemo(() => location.pathname, [location.pathname])

  const toForm = () => {
    // closeDrawer()
    navigate(`/design/${rd.id}`)
  }

  const tm = _.get(tourModels, rd.tourModelId)

  return (
    <NavButton
      isActive={_.includes(pathname, `/${rd.id}`)}
      key={rd.id}
      label={tm?.name}
      color='gray.700'
      iconColor='gray.700'
      lineHeight='5'
      fontWeight='semibold'
      icon={faHouse}
      onClick={toForm}
      options={<Flex alignItems='center'></Flex>}
    />
  )
}

export default SideMenuNavButton
