import { onSnapshot, query, collection, where } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { DictT, ItemsTemplateT } from 'shared/types/model'
import { receiveTemplates } from 'model/actions'

export const fetchTemplates = () => {
  try {
    const q = query(collection(db, 'templates'), where('enabled', '==', true))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<ItemsTemplateT> = {}
        sn.forEach(doc => {
          const p = doc.data() as ItemsTemplateT
          _.set(res, doc.id, p)
        })
        console.log('receive templates ', res)
        store.dispatch(receiveTemplates(res))
      },
      err => {
        console.log(`fetchTemplates: ${err}`)
      }
    )
    addListener('templates', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchTemplates error', e)
  }
}
