import _ from 'lodash'
import { setDoc, doc } from 'firebase/firestore'
import * as Sentry from '@sentry/react'
import {
  AuthError,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import { auth, db } from 'controllers/db'
import { DesignerT } from 'types/model'

export const dbSignIn = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return null
  } catch (e) {
    const er = e as AuthError
    console.log('error', er.code, er.message)
    return er.code
  }
}

export const dbSignUp = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
    const authUser = userCredential.user
    const userId = authUser.uid
    const dbUser: Partial<DesignerT> = {
      id: userId,
      name,
      email,
      createdAt: _.now()
    }
    const ref = doc(db, `designers/${userId}`)
    await setDoc(ref, dbUser, { merge: true })
    console.log('user saved', dbUser)
    return null
  } catch (e) {
    console.log('dbSignUp error', e)
    const er = e as AuthError
    console.log('err code:', er.code)
    console.log('err message:', er.message)
    return er.code
  }
}

export const dbSignOut = async (navigate: NavigateFunction) => {
  try {
    if (auth && auth.currentUser) {
      await signOut(auth)
    } else {
      navigate('/signin')
    }
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
  }
}

export const dbResetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (e) {
    console.warn('password reset email was not sent', e)
    Sentry.captureException(e)
    return false
  }
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  // console.log('custom paramenters', provider.getCustomParameters())
  try {
    const result = await signInWithPopup(auth, provider)
    // console.log('signInWithPopup result', result)
    // This gives you a Google Access Token. You can use it to access the Google API.
    GoogleAuthProvider.credentialFromResult(result)
    console.log('signInWithGoogle result user', result.user)
    const uid = _.get(result, 'user.uid')
    // if (uid) {
    //   logSignInGoogleSuccess(uid)
    // }
    console.log('signInWithPopup uid', uid)
  } catch (error) {
    // logSignInGoogleFailed(_.get(error, 'message', 'unknown'))
    console.error('signInWithGoogle error', error)
  }
}
