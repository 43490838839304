import { onSnapshot, query, collection } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { DictT, RoomTypeT } from 'shared/types/model'
import { receiveRoomTypes } from 'model/actions'

export const fetchRoomTypes = () => {
  try {
    const q = query(collection(db, 'roomTypes'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<RoomTypeT> = {}
        sn.forEach(doc => {
          const p = doc.data() as RoomTypeT
          _.set(res, doc.id, p)
        })
        console.log('receive room types ', res)
        store.dispatch(receiveRoomTypes(res))
      },
      err => {
        console.log(`fetchRoomTypes: ${err}`)
      }
    )
    addListener('roomTypes', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchRoomTypes error', e)
  }
}
