import { useMemo } from 'react'
import { Flex, Wrap } from '@chakra-ui/react'

import { useSelector } from 'model/hooks'
import Loading from 'pages/Loading'
import UnapprovedState from 'components/UnapprovedState'
import _ from 'lodash'
import { dbUpdateUser } from 'controllers/user'
import RequestsNavBar from 'pages/requests/RequestsNavBar'
import RequestsEmptyState from 'pages/requests/RequestsEmptyState'
import { RequestDesignT } from 'types/model'
import RequestCard from 'pages/requests/RequestCard'

const Requests = () => {
  const user = useSelector(state => state.user)
  const requests = useSelector(state => state.requestsDesign)
  const tourModels = useSelector(state => state.tourModels)

  const sortedRequests: RequestDesignT[] = useMemo(() => {
    if (requests) {
      return _.orderBy(requests, 'createdAt', 'desc') as RequestDesignT[]
    } else {
      return [] as RequestDesignT[]
    }
  }, [requests])

  const onRequestAccess = () => {
    if (user && _.isNil(user.requestAccess)) {
      dbUpdateUser(user.id, { requestAccess: _.now() })
    }
  }

  const renderContent = () => {
    if (user) {
      if (!user?.isApproved) {
        return <UnapprovedState onRequestAccess={onRequestAccess} user={user} />
      } else if (_.isNil(requests)) {
        return <Loading />
      } else if (_.isEmpty(requests)) {
        return (
          <>
            <RequestsNavBar />
            <RequestsEmptyState />
          </>
        )
      } else {
        return (
          <>
            <RequestsNavBar />
            <Flex p={4} overflowY='auto' h='full'>
              <Wrap spacing='8' overflowY='auto'>
                {_.map(sortedRequests, rd => {
                  const tm = _.get(tourModels, rd.tourModelId)
                  if (tm) {
                    return <RequestCard key={rd.id} tm={tm} rd={rd} />
                  } else {
                    return null
                  }
                })}
              </Wrap>
            </Flex>
          </>
        )
      }
    }
  }

  if (!user) {
    return <Loading />
  } else {
    return (
      <Flex
        direction='column'
        h='full'
        w='full'
        bg='gray.50'
        overflow={'hidden'}
      >
        {renderContent()}
      </Flex>
    )
  }
}

export default Requests
