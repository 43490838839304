import { FC, useMemo } from 'react'
import _ from 'lodash'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'model/hooks'
import { getAppLoaded } from 'model/selectors/base'
import NavWrapper from 'navigation/NavWrapper'
// import Designs from 'pages/Designs'
import EditTour from 'pages/EditTour'
import SignIn from 'pages/auth/SignIn'
import SignUp from 'pages/auth/SignUp'
import Loading from 'pages/Loading'
import Settings from 'pages/Settings'
import DashboardWrapper from 'pages/dashboard/DashboardWrapper'
import AuthPageLayout from 'pages/auth/AuthPageLayout'
import ForgotPassword from 'pages/auth/ForgotPassword'
import Requests from 'pages/Requests'
import Completed from 'pages/Completed'

const AppRouter: FC<{}> = () => {
  const authData = useSelector(state => state.authData)
  const appLoaded = useSelector(getAppLoaded)

  const publicRoutes = (
    <Route path='/auth' element={<AuthPageLayout />}>
      <Route path='signin' element={<SignIn />} />
      <Route path='signup' element={<SignUp />} />
      <Route path='reset_password' element={<ForgotPassword />} />
    </Route>
  )

  const privateRoutes = useMemo(() => {
    if (_.isNil(authData) || !_.isNil(appLoaded)) {
      return <Route path='*' element={<Loading />} />
    } else {
      return (
        <>
          <Route path='/' element={<DashboardWrapper />}>
            <Route path='/requests' element={<Requests />} />
            <Route path='/completed' element={<Completed />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/' element={<Navigate replace to='/requests' />} />
            <Route path='*' element={<Navigate replace to='/requests' />} />
          </Route>
          <Route path='/design/:requestDesignId' element={<EditTour />} />
        </>
      )
    }
  }, [authData, appLoaded])

  return (
    <BrowserRouter basename='/'>
      <NavWrapper>
        <Routes>
          {privateRoutes}
          {publicRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
