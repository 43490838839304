import { useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Text,
  useColorModeValue,
  VStack,
  HStack,
  Divider
} from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/logo_only.svg'
import {
  faBallotCheck,
  faCircleQuestion
} from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'model/hooks'
import AccountButton from 'components/sidebar/AccountButton'
import { NavButton } from 'components/sidebar/NavButton'
import { RequestDesignT } from 'types/model'
import SideMenuNavButton from 'components/sidebar/SideMenuNavButton'

const DashboardSidebar = () => {
  const navigate = useNavigate()
  const pathname = window.location.pathname
  const requests = useSelector(state => state.requestsInProgress)

  const sortedRequests = useMemo(() => {
    return _.orderBy(requests, 'createdAt', 'desc')
  }, [requests])

  const toRequests = () => {
    navigate('/requests')
  }

  const toCompleted = () => {
    navigate('/completed')
  }

  const renderBottomSection = () => {
    return (
      <VStack w='full' spacing={0}>
        <Divider pt={4} m={0} />
        <HStack
          py={{ base: '2', sm: '5' }}
          // px={{ base: '2', sm: '5' }}
          mt={0}
          w='full'
          bg={_.startsWith(pathname, '/settings') ? 'gray.200' : 'white'}
        >
          <AccountButton />
        </HStack>
      </VStack>
    )
  }

  const renderLogo = () => {
    return (
      <HStack
        px={4}
        w='full'
        pt={4}
        alignItems='center'
        justifyContent='flex-start'
        spacing={2}
      >
        <Logo height='26px' width='26px' />
        <Text fontSize={'2xl'} fontWeight='800' color='teal.900' pt={1}>
          UpStager
        </Text>
      </HStack>
    )
  }

  const renderTopMenu = () => {
    return (
      <Flex flexShrink={0} w='full' px={4} pt={6}>
        <VStack w='full'>
          <NavButton
            label='Requests'
            fontSize='md'
            fontWeight='semibold'
            color='gray.500'
            iconColor='gray.500'
            icon={faCircleQuestion}
            onClick={toRequests}
            isActive={_.startsWith(pathname, '/requests')}
          />
          <NavButton
            label='Completed'
            fontSize='md'
            fontWeight='semibold'
            color='gray.500'
            iconColor='gray.500'
            icon={faBallotCheck}
            onClick={toCompleted}
            isActive={_.startsWith(pathname, '/completed')}
          />
        </VStack>
      </Flex>
    )
  }

  const renderTours = () => {
    if (!_.isEmpty(sortedRequests)) {
      return (
        <VStack
          spacing={3}
          overflow='hidden'
          minH='24'
          flex-grow='1'
          flex-basis='0'
          w='full'
          align='flex-start'
          justify={'flex-start'}
          px={4}
          h='full'
          pt={6}
        >
          <Text
            fontSize='xs'
            letterSpacing='0.05em'
            textTransform='uppercase'
            color='gray.500'
          >
            IN PROGRESS
          </Text>
          <VStack
            spacing='1'
            overflowY='auto'
            h='100%'
            justify={'flex-start'}
            w='full'
          >
            {_.map(sortedRequests, (rd: RequestDesignT) => (
              <SideMenuNavButton key={rd.id} rd={rd} />
            ))}
          </VStack>
        </VStack>
      )
    }
  }

  return (
    <Flex h='full' w='full' flexShrink={0} bg='white'>
      <Flex boxShadow={useColorModeValue('sm', 'sm-dark')} w='full'>
        <VStack
          justify='space-between'
          spacing='1'
          width='full'
          flexShrink={0}
          flexGrow={0}
        >
          <VStack w='full' spacing={0}>
            {renderLogo()}
            {renderTopMenu()}
          </VStack>
          {/* <VStack spacing={1} flex={1} pt={14} w='full'>
            {linkItems.map(renderNavItem)}
          </VStack> */}
          {renderTours()}
          {renderBottomSection()}
        </VStack>
      </Flex>
    </Flex>
  )
}

export default DashboardSidebar
