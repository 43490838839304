import { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'model/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { dbDeleteTourSlot, dbUpdateTourPath } from 'controllers/tours'
import { addMattertag, removeMattertag, clearItems } from 'model/actions'
import { generateId } from 'controllers/db'
import { AddItemT, RequestDesignT, TourSlotT } from 'types/model'
import { getRoomTypesWithTemplates } from 'model/selectors/templatesSelector'
import EditTourNavBar from 'pages/editTour/EditTourNavBar'
import UserItemsPanel from 'pages/editTour/UserItemsPanel'
import { dbMarkDesignCompleted } from 'controllers/requests'
import { dbFetchItemsList, dbGetItemsBySlot } from 'controllers/items'
import { dbUpdateSlotsLastItemId } from 'controllers/slots'
import TourView from 'shared/components/TourView'

const EditTour = () => {
  const dispatch = useDispatch()
  const mattertags = useSelector(state => state.mattertags)

  const suppliers = useSelector(state => state.suppliers)
  const items = useSelector(state => state.items)
  const slots = useSelector(state => state.slots)
  const roomTypesWithTemplates = useSelector(getRoomTypesWithTemplates)
  const { requestDesignId } = useParams() as { requestDesignId: string }
  const navigate = useNavigate()
  const itemsNotFoundRef = useRef<string[]>([])
  const rd = useSelector(state =>
    _.get(state.requestsInProgress, requestDesignId,
      _.find(state.requestsCompleted, ({ id }) => id === requestDesignId)
    )
  )
  const tour = useSelector(state =>
    rd && rd.tourId ? _.get(state, ['tours', rd.tourId || ''], null) : null
  )
  const tourModels = useSelector(state => state.tourModels)

  useEffect(() => {
    const run = async () => {
      if (tour) {
        const itemsIdsToLoad = _.uniq(_.map(tour.slots, ts => ts.itemId))
        console.log('itemsIdsToLoad', itemsIdsToLoad)
        const currentIds = _.keys(items)
        console.log('currentIds', currentIds)
        const diff = _.difference(itemsIdsToLoad, currentIds)
        console.log('diff', diff)
        const finalDiff = _.difference(diff, itemsNotFoundRef.current)
        console.log('finalDiff', finalDiff)
        if (!_.isEmpty(finalDiff)) {
          const nf = await dbFetchItemsList(finalDiff)
          itemsNotFoundRef.current = _.uniq([
            ...itemsNotFoundRef.current,
            ...nf
          ])
        }
      }
    }
    run()
  }, [items, tour?.slots])

  useEffect(() => {
    return () => {
      dispatch(clearItems())
    }
  }, [])

  useEffect(() => {
    if (tour) {
      const partnerId = _.get(tourModels, [tour.tourModelId, 'partnerId'])
      if (!_.isUndefined(partnerId)) {
        dbUpdateSlotsLastItemId(partnerId)
      }
    }
  }, [tourModels, tour?.tourModelId])

  const removeTourSlot = (slotId: string) => {
    if (tour) {
      dbDeleteTourSlot(tour.id, slotId)
      dispatch(removeMattertag({ tourSlotId: slotId }))
    }
  }

  const updateTourSlot = async (
    slotId: string,
    key: keyof TourSlotT,
    value: any
  ) => {
    if (tour) {
      dbUpdateTourPath(tour.id, `slots.${slotId}.${key}`, value)
    }
  }

  const addItems = (aItems: AddItemT[]) => {
    if (tour) {
      _.forEach(aItems, ({ itemId, position, rotation }: AddItemT) => {
        const id = generateId()
        const ts: TourSlotT = {
          id,
          itemId,
          position,
          createdAt: _.now(),
          rotation
        }
        dbUpdateTourPath(tour.id, `slots.${ts.id}`, ts)
      })
    }
  }

  const onResetDesign = () => {
    if (tour) {
      dbUpdateTourPath(tour.id, 'slots', {})
    }
  }

  const onMattertagCreated = (tourSlotId: string, mtId: string) => {
    dispatch(addMattertag({ tourSlotId, tagId: mtId }))
  }

  const onMarkCompleted = async () => {
    console.log('onMarkCompleted')
    await dbMarkDesignCompleted(rd as RequestDesignT)
    navigate('/requests')
  }

  const getItems = (slotId: string, amount: number, offset: number) => {
    let partnerId = null
    if (tour) {
      const tm = _.get(tourModels, tour.tourModelId)
      if (tm) {
        partnerId = tm.partnerId
      }
    }
    console.log('getItems', slotId, amount, offset, tour)
    return dbGetItemsBySlot(slotId, amount, offset, partnerId)
  }

  const renderTourView = () => {
    if (tour && tourModels && rd) {
      const tourModel = tourModels[tour.tourModelId]
      if (tourModel) {
        return (
          // <TourView key={tour.id} tour={tour} tourModel={tourModel} rd={rd} />
          <TourView
            key={tour.id}
            tourId={tour.id}
            tourModelId={tour.tourModelId}
            tourSlots={tour.slots}
            modelId={tourModel.modelId}
            deleteSlot={removeTourSlot}
            updateTourSlot={updateTourSlot}
            onResetDesign={onResetDesign}
            addItems={addItems}
            items={items}
            slots={slots}
            suppliers={suppliers}
            mattertags={mattertags}
            onMattertagCreated={onMattertagCreated}
            EditTourNavBar={EditTourNavBar}
            UserItemsPanel={UserItemsPanel}
            designName={tour.name}
            roomTypesWithTemplates={roomTypesWithTemplates}
            isCompleted={rd.completed}
            onMarkCompleted={onMarkCompleted}
            loadItems={(itemsIds: string[]) => dbFetchItemsList(itemsIds)}
            getItems={getItems}
            canEdit
            pricesVisible
          />
        )
      }
    }
  }

  return (
    <Box position='relative' w='full' h='full'>
      {renderTourView()}
    </Box>
  )
}

export default EditTour
