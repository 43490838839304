import { Flex, Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import DashboardSidebar from 'pages/dashboard/DashboardSidebar'

const DashboardWrapper = () => {
  return (
    <Flex minH='100vh' h='full' bg={'gray.100'} direction='row'>
      <Box h='full' w={72} flexShrink={0}>
        <DashboardSidebar />
      </Box>
      <Flex h='full' w='full' bg='gray.100' overflow={'hidden'}>
        <Outlet />
      </Flex>
    </Flex>
  )
}

export default DashboardWrapper
