import {
  Flex,
  Wrap,
  WrapItem,
  Image,
  HStack,
  VStack,
  Text,
  IconButton
} from '@chakra-ui/react'
import moment from 'moment'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPen } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router'

import { useSelector } from 'model/hooks'
import { RequestDesignT } from 'shared/types/model'
import { noImageUrl } from 'shared/constants/helpers'
import config from 'shared/config'

const CompletedContent = () => {
  const rds = useSelector(state => state.requestsCompleted)
  const tourModels = useSelector(state => state.tourModels)
  const navigate = useNavigate()

  const renderRD = (rd: RequestDesignT) => {
    const tm = _.get(tourModels, rd.tourModelId)
    return (
      <WrapItem key={rd.id} w='xs' position='relative' rounded={'xl'}>
        <Image
          src={tm?.photoUrl || noImageUrl}
          rounded={'xl'}
          objectFit={'cover'}
        />
        <Flex
          w='full'
          h='full'
          position='absolute'
          left={0}
          top={0}
          rounded={'xl'}
          direction='column'
          justify={'space-between'}
        >
          <Flex
            flex={1}
            rounded='xl'
            mb={8}
            px={2}
            justify='flex-end'
            align='flex-start'
            position='relative'
            my={4}
          >
            <IconButton
              color='white'
              aria-label='view tour'
              icon={<FontAwesomeIcon icon={faPen} />}
              variant='link'
              onClick={() => navigate(`/design/${rd.id}`)}
            />
            <IconButton
              color='white'
              aria-label='view tour'
              icon={<FontAwesomeIcon icon={faEye} />}
              variant='link'
              onClick={() =>
                window.open(`${config.userAppUrl}/${rd.tourModelId}?d=${rd.tourId}`, '_blank')}
            />
          </Flex>
          <HStack
            flex={1}
            rounded='xl'
            bgGradient={'linear(to-b, #00000000, #000000aa)'}
            justify='space-between'
            align='flex-end'
            px={4}
            py={6}
          >
            <HStack justify='space-between' align='flex-end' w='full'>
              <VStack spacing={0} align='flex-start'>
                <Text
                  fontSize='2xl'
                  px={2}
                  w='100%'
                  color='white'
                  lineHeight={1.4}
                >
                  {_.get(tm, 'name', 'Request design')}
                </Text>
                <Text
                  px={2}
                  color='white'
                  fontWeight={'normal'}
                  fontSize='sm'
                  textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
                >
                  Completed at: {moment(rd.createdAt).format('lll')}
                </Text>
              </VStack>
            </HStack>
          </HStack>
        </Flex>
      </WrapItem>
    )
  }

  return (
    <Flex p={4} overflowY='auto' h='full'>
      <Wrap overflowY='auto'>{_.map(rds, renderRD)}</Wrap>
    </Flex>
  )
}

export default CompletedContent
