import { FC } from 'react'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HStack, IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

type Props = {
  visible: boolean
}

const EditTourNavBar: FC<Props> = ({ visible }) => {
  const navigate = useNavigate()

  const toDashboard = () => {
    navigate('/requests')
  }

  if (visible) {
    return (
      <HStack
        direction='row'
        position={'absolute'}
        align='center'
        top={5}
        left={5}
        zIndex={100}
      >
        <IconButton
          aria-label='go_back'
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          onClick={toDashboard}
        />
      </HStack>
    )
  } else {
    return null
  }
}

export default EditTourNavBar
